@media (prefers-color-scheme: dark) {
  body {
    background-color: #1F1F21;
    color: #F7F7F7;
  }

  .jumbotron {
    background-color: #252527;
  }
}
