$primary: #FF5000;
$active-color: $primary;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~@bootstrap-extended-style/bootstrap-extended-style/index";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/duotone";

@import "cookie-consent";
@import "dark-mode";

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-y: scroll;
}

.jumbotron-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
  background-color: #2D3032;
  margin-bottom: 0;
  padding-top: 30px;
  padding-bottom: 10px;
}
