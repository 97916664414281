.cookie-consent-container {
  z-index: 999;

  position: fixed;
  left: 0;
  bottom: 10px;
  width: 100%;

  .cookie-consent-banner {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 576px) {
      max-width: 540px;
    }
    @media (min-width: 768px) {
      max-width: 720px;
    }
    @media (min-width: 992px) {
      max-width: 960px;
    }
    @media (min-width: 1200px) {
      max-width: 1140px;
    }

    border-radius: 5px;
    background-color: rgba(29, 29, 29, 0.75);
    backdrop-filter: blur(.25rem);

    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .cookie-consent-content {
      color: white;
      flex: 1 0 300px;
      margin: 20px;
    }
    .cookie-consent-button {
      cursor: pointer;
      flex: 0 0 auto;
      padding: 5px 10px;
      margin: 15px;
    }
  }
}
